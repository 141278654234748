export const enum RetrievalMode {
    Hybrid = "hybrid",
    Vectors = "vectors",
    Text = "text"
}

export const enum ChatModel {
    GPT_35_TURBO = "gpt-35-turbo",
    GPT_35_TURBO_16K = "gpt-35-turbo-16k",
    GPT_4 = "gpt-4",
    GPT_4_32K = "gpt-4-32k"
}

export const enum Locale {
    JA = "ja",
    EN = "en"
}

export type ChatAppRequestOverrides = {
    chat_model?: ChatModel;
    only_citations?: boolean;
    retrieval_mode?: RetrievalMode;
    language?: string;
    semantic_ranker?: boolean;
    semantic_captions?: boolean;
    exclude_category?: string;
    top?: number;
    temperature?: number;
    prompt_template?: string;
    prompt_template_prefix?: string;
    prompt_template_suffix?: string;
    suggest_followup_questions?: boolean;
    use_oid_security_filter?: boolean;
    use_groups_security_filter?: boolean;
    product_filter?: any;
    doctype_filter?: string;
    sub_product_filter?: any;
    product_version?: string;
    version_index?: string;
    template_keys?: any;
    templateContents?: string;
};

export type ResponseMessage = {
    content: string;
    role: string;
};

export type ResponseContext = {
    thoughts: string | null;
    data_points: [DataPoints] | [];
    followup_questions: string[] | null;
    keywords: string | null;
    feedback?: FeedbackResponse;
    chat_model: string | null;
    product_filter: string | null;
    sub_product_filter: string | null;
    app_type: string | null;
    language: string | null;
    document_category?: string | null;
    feedback_corp_id: string | null;
    feedback_user: string | null;
    feedbackgiven: boolean;
    feedbacktype: string | null;
    corpId?: string | null;
    userId?: string | null;
    productVersion: string | null;
    versionIndex: string | null;
    file_mapping: object | null;
};

export type ResponseChoice = {
    index: number;
    message: ResponseMessage;
    context: ResponseContext;
    session_state: any;
};

export type ChatAppResponseOrError = {
    choices?: ResponseChoice[];
    error?: string;
};

export type ChatAppResponse = {
    choices: ResponseChoice[];
};

export type ChatAppRequestContext = {
    overrides?: ChatAppRequestOverrides;
};

export type ChatAppRequest = {
    messages: ResponseMessage[];
    context?: ChatAppRequestContext;
    stream?: boolean;
    session_state: any;
};

export type SaveResponse = {
    feedbacks: object;
    shouldStream?: boolean;
};

export type Feedback = {
    chat_id?: string;
    user_id?: string;
    text: string;
    type: string;
    options?: number;
};

export type AdminFeedback = {
    chat_id?: string;
    user_id?: string;
    text: string;
    type: string;
    options?: number;
};
export type FeedbackResponse = {
    status?: string | boolean;
    category?: number | string;
    c_date?: string;
    data?: { text: string; type: number; time: string };
};

export type DataPoints = {
    uploaddir_field: string;
    sourcepage_field: string;
    content_field: string;
    search_score?: string;
    search_reranker_score?: string;
    doc_url?: string;
    doctype?: string;
    hasPassword: boolean;
};

export type DeveloperOptions = {
    top: number;
    chat_model: ChatModel;
    retrieval_mode: RetrievalMode;
    only_citations: boolean;
    semantic_ranker: boolean;
    semantic_captions: boolean;
    suggest_followup_questions: boolean;
};

export type SessionInfo = {
    title?: string;
    conversation_date?: string;
    session_id?: string;
    corp_id?: string;
    user_id?: string;
    group?: number;
};

export type DataOptions = {
    chat_model: ChatModel;
    product_filter?: string;
    doctype_filter?: string;
    shared_chat?: boolean;
    sub_product_filter?: string;
    app_type?: string;
    productVersion?: string;
    versionIndex?: string;
};

export interface YearData {
    year: string;
    sessions: {
        title: string;
        month: number;
        monthName: string;
        records: SessionInfo[];
    }[];
}
