import { ChatAppResponse, ChatAppResponseOrError, ChatAppRequest, SaveResponse } from "./models";
import { backendUri, jwtToken, supportEnvHost } from "../authConfig";
import { jwtDecode } from "jwt-decode";

type TokenValues = {
    exp: number;
    isInchargeOfAiWorks: string;
    user_name: string;
};
const BACKEND_URI = backendUri ?? "";

function getHeaders(): HeadersInit {
    const headers: Record<string, string> = {
        "Content-Type": "application/json"
    };
    if (jwtToken && jwtToken != "" && jwtToken != undefined) {
        headers["Authorization"] = `Bearer ${jwtToken}`;
    }
    return headers;
}

export async function askApi(request: ChatAppRequest): Promise<ChatAppResponse> {
    const response: any = validateTokens("ask", "POST", JSON.stringify(request));

    const parsedResponse: ChatAppResponseOrError = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse as ChatAppResponse;
}

export async function chatApi(request: ChatAppRequest, selectedApp: string, corpId: string): Promise<Response | any> {
    return validateTokens("chat", "POST", JSON.stringify({ request: request, appType: selectedApp, corpId: corpId }));
}

export async function saveChatApi(history: object): Promise<Response | any> {
    return validateTokens(
        "save-chat",
        "POST",
        JSON.stringify({
            history: history,
            group_id: sessionStorage.getItem("sessionId")
        })
    );
}
export async function feedbackApi(options: SaveResponse, chat_id: string | undefined): Promise<Response | any> {
    return validateTokens(
        "save-feedback",
        "POST",
        JSON.stringify({
            feedbacks: options.feedbacks,
            chat_id: chat_id
        })
    );
}

export async function saveAskApi(history: object): Promise<Response | any> {
    return validateTokens(
        "save-chat",
        "POST",
        JSON.stringify({
            history: history,
            group_id: "ASK"
        })
    );
}

export async function loadSharedChatApi(sessionId: string, corpcode: string): Promise<Response | any> {
    return validateTokens(
        "load-shared-chat",
        "POST",
        JSON.stringify({
            group_id: sessionId,
            corpcode: corpcode
        })
    );
}

export async function copySharedChatApi(sharedSessionId: string, newSessionID: string | null): Promise<Response | any> {
    return validateTokens(
        "copy-shared-chat",
        "POST",
        JSON.stringify({
            group_id: sharedSessionId,
            new_group_id: newSessionID
        })
    );
}
export async function loadChatApi(sessionId: unknown): Promise<Response | any> {
    return validateTokens(
        "load-chat",
        "POST",
        JSON.stringify({
            group_id: sessionId
        })
    );
}

export async function loadUsersApi(): Promise<Response | any> {
    return validateTokens("load-users", "POST", JSON.stringify({}));
}

export async function loadSessionApi(nextDateToLoad: string): Promise<Response | any> {
    return validateTokens("load-sessions", "POST", JSON.stringify({ nextDate: nextDateToLoad }));
}

export async function fetchUserDataApi(): Promise<Response | any> {
    return validateTokens("fetch-userdata", "POST", JSON.stringify({}));
}

export async function loadFilteredSessionsApi(filters: object): Promise<Response | any> {
    return validateTokens(
        "load-filtered",
        "POST",
        JSON.stringify({
            filters: filters
        })
    );
}

export async function loadMetricsDataApi(): Promise<Response | any> {
    return validateTokens("load-metrics", "POST", JSON.stringify({}));
}

export async function updateMetricsDataApi(): Promise<Response | any> {
    return validateTokens("update-metrics", "POST", JSON.stringify({}));
}

export function getCitationFilePath(citation: string): string {
    return `${BACKEND_URI}/content/${citation}`;
}

export async function saveUserDetails(
    docValue: string,
    product: string,
    locale: string,
    aimodel: string,
    isSidebarOpen: string,
    apptype: string,
    faqProduct: string,
    subProduct: string,
    chatTourDone: string,
    answerTourDone: string,
    productVersion: string,
    versionIndex: string,
    templateTourDone: string
): Promise<Response | any> {
    return validateTokens(
        "save-user-details",
        "POST",
        JSON.stringify({
            docValue: docValue,
            product: product,
            locale: locale,
            aimodel: aimodel,
            isSidebarOpen: isSidebarOpen,
            apptype: apptype,
            faqProduct: faqProduct,
            subProduct: subProduct,
            chatTourDone: chatTourDone,
            answerTourDone: answerTourDone,
            productVersion: productVersion,
            versionIndex: versionIndex,
            templateTourDone: templateTourDone
        })
    );
}
export async function fetchAppInfo(): Promise<Response | any> {
    const queryParams = new URLSearchParams();
    queryParams.append("product_list", JSON.stringify([]));
    queryParams.append("load_products", JSON.stringify(false));
    return validateTokens("load-app-info?" + queryParams, "GET", JSON.stringify({}));
}

export async function saveAppSettings(appInfo: string): Promise<Response | any> {
    return validateTokens(
        "save-settings",
        "POST",
        JSON.stringify({
            appInfo: appInfo
        })
    );
}

export async function fetchUpdateInfo(): Promise<Response | any> {
    return validateTokens("load-update-info", "GET", JSON.stringify({}));
}

export async function saveAppUpdates(updates: string): Promise<Response | any> {
    return validateTokens(
        "save-updates",
        "POST",
        JSON.stringify({
            updates: updates
        })
    );
}

export async function saveSharedChatValueApi(sessionValue: string, corp_id: string): Promise<Response | any> {
    return validateTokens(
        "save-shared-chat-value",
        "POST",
        JSON.stringify({
            group_id: sessionValue,
            corpcode: corp_id
        })
    );
}

export async function deleteFollowupConversation(conversationDate: any): Promise<Response | any> {
    return validateTokens(
        "delete-followup-conversation",
        "POST",
        JSON.stringify({
            group_id: sessionStorage.getItem("sessionId"),
            conversation_date: conversationDate
        })
    );
}

export async function saveTermsAcceptance(): Promise<Response | any> {
    return validateTokens("save-terms-accept", "POST", JSON.stringify({}));
}

export async function saveTemplate(template: object): Promise<Response> {
    return validateTokens(
        "save-template",
        "POST",
        JSON.stringify({
            template: template
        })
    );
}

export async function loadTemplate(): Promise<Response> {
    return validateTokens("load-template", "POST", JSON.stringify({}));
}

export async function updateTemplate(id: string, template: object, order: number): Promise<Response> {
    return validateTokens(
        "update-template",
        "POST",
        JSON.stringify({
            id: id,
            template: template,
            order: order
        })
    );
}

export async function deleteTemplate(id: string): Promise<Response> {
    return validateTokens(
        "delete-template",
        "POST",
        JSON.stringify({
            id: id
        })
    );
}

async function validateTokens(endPoint: string, methodType: string, body: BodyInit | null | undefined): Promise<Response | any> {
    try {
        if (jwtToken && jwtToken != "" && jwtToken != undefined) {
            const tokenValues = jwtDecode(jwtToken ?? "") as TokenValues;
            if (tokenValues?.exp < Date.now() / 1000) {
                alert(
                    "セッションが切れました。@SUPPORTにログイン後、本URLに再度アクセスしてください。\n-----\nSession Expired... Please login via @SUPPORT and try again."
                );
                window.location.href = supportEnvHost;
                return JSON.stringify({ error: "token_expired" });
            }
        } else {
            alert(
                "セッションが切れました。@SUPPORTにログイン後、本URLに再度アクセスしてください。\n-----\nInvalid Session...Please login via @SUPPORT and try again."
            );
            window.location.href = supportEnvHost;
            return JSON.stringify({ error: "token_unavailable" });
        }
        let response: any;
        if (methodType === "GET") {
            response = await fetch(`${BACKEND_URI}/${endPoint}`, {
                method: methodType,
                headers: getHeaders()
            });
        } else {
            response = await fetch(`${BACKEND_URI}/${endPoint}`, {
                method: methodType,
                headers: getHeaders(),
                body: body
            });
        }
        if (!response?.ok) {
            const result = await response.json();
            if (result?.error_code) {
                alert(`${result?.error}. Please login again`);
                window.location.href = supportEnvHost;
            }
        }
        return response;
    } catch (error) {
        console.log(error);
        alert("リクエストの処理で問題が発生しました。\n-----\nError occured. Please try again...");
    }
}
