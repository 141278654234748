import { Outlet, NavLink, Link } from "react-router-dom";
import wap from "../../assets/wap.svg";
import styles from "./Layout.module.css";
import { useState } from "react";
import { DefaultButton, Dropdown, IDropdownOption } from "@fluentui/react";
import { AlertBadge24Filled, Power24Filled, QuestionCircle24Filled } from "@fluentui/react-icons";
import { appInfo, userInfo, jwtToken, supportEnvHost } from "../../authConfig";
import { Locale, saveUserDetails } from "../../api";
import i18next from "../../i18n/i18n";
import Book from "../../assets/book.svg";
import Question from "../../assets/questionnaire.svg";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { Tooltip } from "@fluentui/react-components";
import { Box, IconButton } from "@mui/material";
import { Menu, MenuOpen } from "@mui/icons-material";

type TokenValues = {
    isInchargeOfAiWorks: string;
    userSupportId: string;
};

interface Props {
    callback: () => void;
    startTour: (start: boolean) => void;
    openSidebarCallback: (open: boolean) => void;
    isSidebarOpen: boolean;
}

export let userSupportId = "bot@ai-works";

const Layout = ({ callback, startTour, openSidebarCallback, isSidebarOpen }: Props) => {
    const tokenValues = jwtDecode(jwtToken || "") as TokenValues;
    const isAdmin = tokenValues["isInchargeOfAiWorks"] === "True";
    userSupportId = tokenValues["userSupportId"];

    const localeStr: string = userInfo.locale || Locale.JA;
    sessionStorage.setItem("selectedLocale", userInfo.locale || Locale.JA);
    // Type assertion
    const localeEnum: Locale = localeStr as Locale;
    const [locale, setLocale] = useState<Locale>(localeEnum);

    const locales = appInfo.locales;

    const localeOptions = Object.keys(locales).map(key => ({
        key: key.toLowerCase(),
        text: locales[key],
        selected: locale === key,
        data: key
    }));
    const onLocaleChange = (_ev?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<Locale> | undefined) => {
        setLocale(option?.data ?? Locale.JA);
        sessionStorage.setItem("selectedLocale", option?.data ?? Locale.JA);
        saveUserSettings("", "", option?.data ?? Locale.JA, "", "", "", "", "", "", "", "", "", "", true);
        userInfo.locale = option?.data ?? Locale.JA;
    };

    const saveUserSettings = async (
        docValue: string,
        productValue: string,
        locale: string,
        aimodel: string,
        isSidebarOpen: string,
        apptype: string,
        faqProduct: string,
        subProduct: string,
        chatTourDone: string,
        answerTourDone: string,
        productVersion: string,
        versionIndex: string,
        templateTourDone: string,
        reloadNeeded?: boolean
    ) => {
        try {
            await saveUserDetails(
                docValue,
                productValue,
                locale,
                aimodel,
                isSidebarOpen,
                apptype,
                faqProduct,
                subProduct,
                chatTourDone,
                answerTourDone,
                productVersion,
                versionIndex,
                templateTourDone
            )
                .then(response => {
                    return response.json();
                })
                .then(() => {
                    if (reloadNeeded) {
                        window.location.reload();
                    }
                })
                .catch(error => {
                    console.error("Error saving user data:", error);
                });
        } catch (error) {
            console.error("Error saving user data:", error);
        }
    };

    const iconStyle = {
        width: "30px",
        height: "30px",
        padding: "5px"
    };
    const handleClick = () => {
        callback();
    };
    const handleLogout = () => {
        Cookies.remove("token", { path: "/" });
        Cookies.remove("token", { path: "/assistant" });
        window.location.href = supportEnvHost.split("/aiworks.do")[0] + "/view/wap/top";
    };

    const handleTourStart = () => {
        startTour(true);
    };

    const handleDrawerOpen = () => {
        const newisSidebarOpen = !isSidebarOpen;
        openSidebarCallback(newisSidebarOpen);
        userInfo.isSidebarOpen = newisSidebarOpen.toString();
        saveUserSettings("", "", "", "", newisSidebarOpen.toString(), "", "", "", "", "", "", "", "");
    };

    return (
        <Box className={styles.layout}>
            <div style={{ display: "flex", backgroundColor: "#FFEFC9", flexDirection: "row", justifyContent: "space-between" }}>
                {userInfo?.isWap === "True" && (
                    <>
                        <div style={{ paddingLeft: "20px", display: "flex" }}>
                            <img src={Book} alt="Icon" style={iconStyle} />
                            <Link
                                style={{ textDecoration: "underline", alignSelf: "center" }}
                                to=""
                                onClick={() => {
                                    window.open(
                                        "https://docs.google.com/spreadsheets/d/1p-MiAJxyiOjYwd-bTNjx152GmVfBXF8XvyIsK7q8aM0/edit?usp=sharing",
                                        "_blank"
                                    );
                                }}
                            >
                                {i18next.t("layout.manual")}
                            </Link>
                        </div>
                        <div style={{ paddingLeft: "30px", display: "flex" }}>
                            <img src={Question} alt="Icon" style={iconStyle} />
                            <Link
                                style={{ textDecoration: "underline", alignSelf: "center" }}
                                to=""
                                onClick={() => {
                                    window.open(
                                        "https://docs.google.com/forms/d/e/1FAIpQLSd5GOsfVU7SriBrt3UAJhT-5UApFapLWa8HUnMd3GkvlppdhA/viewform?usp=sf_link",
                                        "_blank"
                                    );
                                }}
                            >
                                {i18next.t("layout.feedbackForm")}
                            </Link>
                        </div>
                    </>
                )}
                <div style={{ marginLeft: "auto", display: "flex" }}>
                    <span style={{ alignSelf: "center", color: "red" }}>* {i18next.t("layout.bannerText")}</span>
                </div>
            </div>
            <header className={styles.header} role={"banner"}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    style={{ position: "absolute", top: "22px", padding: "0px 30px" }}
                    title={isSidebarOpen ? i18next.t("layout.closeDrawer") : i18next.t("layout.openDrawer")}
                >
                    {!isSidebarOpen ? <Menu /> : <MenuOpen />}
                </IconButton>
                <div className={styles.headerContainer}>
                    <div className={styles.headerTitleContainer}>
                        <img src={wap} alt={i18next.t("layout.wap")} aria-label={i18next.t("layout.wap")} style={{ marginRight: "10px" }} />
                        <h3 className={styles.headerTitle}>{i18next.t("layout.headerTitle")}</h3>
                    </div>
                    <nav>
                        {isAdmin ? (
                            <ul className={styles.headerNavList}>
                                <li>
                                    <NavLink
                                        to="/assistant/chat"
                                        className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}
                                        onClick={handleClick}
                                    >
                                        {i18next.t("layout.chat")}
                                    </NavLink>
                                </li>

                                <li className={styles.headerNavLeftMargin}>
                                    <NavLink
                                        to="/assistant/feedbacks"
                                        className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}
                                    >
                                        {i18next.t("layout.feedbacks")}
                                    </NavLink>
                                </li>
                                <li className={styles.headerNavLeftMargin}>
                                    <NavLink
                                        to="/assistant/reports"
                                        className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}
                                    >
                                        {i18next.t("layout.reports")}
                                    </NavLink>
                                </li>
                                <li className={styles.headerNavLeftMargin}>
                                    <NavLink
                                        to="/assistant/settings"
                                        className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}
                                    >
                                        {i18next.t("layout.settings")}
                                    </NavLink>
                                </li>
                            </ul>
                        ) : null}
                    </nav>
                    {/* <h4 className={styles.headerRightText}>{i18next.t("layout.appName")}</h4> */}
                    <h4 className={styles.headerRightTextTwo}>{userSupportId}</h4>
                    <div style={{ padding: "5px", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        <div style={{ marginTop: "8px", cursor: "pointer" }}>
                            <Tooltip
                                content={{
                                    children: i18next.t("appTour.tourIcon"),
                                    style: {
                                        backgroundColor: "white"
                                    }
                                }}
                                relationship="label"
                            >
                                <QuestionCircle24Filled onClick={handleTourStart} style={{ backgroundColor: "none", color: "white" }} />
                            </Tooltip>
                        </div>
                        <div style={{ marginTop: "8px", marginLeft: "10px", marginRight: "10px" }}>
                            <Link
                                to="/assistant/updates"
                                target="_blank"
                                // className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}
                                title={i18next.t("layout.updates")}
                            >
                                <AlertBadge24Filled style={{ backgroundColor: "none", color: "white" }} />
                            </Link>
                        </div>
                        <div className="Locale" style={{ width: "100px", marginLeft: "10px", marginRight: "10px" }}>
                            <Dropdown id="LangDropdown" selectedKey={locale} placeholder="Locale" options={localeOptions} onChange={onLocaleChange} />
                        </div>
                        {
                            <DefaultButton style={{ borderRadius: "15px" }}>
                                <Power24Filled onClick={handleLogout} />
                            </DefaultButton>
                        }
                    </div>
                </div>
            </header>

            <Outlet />
        </Box>
    );
};

export default Layout;
